import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react'
import {Canvas, useFrame, useThree} from '@react-three/fiber'
import "../../../css/Playroom.css";
import {Environment, OrbitControls, PerspectiveCamera, Preload, useEnvironment} from "@react-three/drei";
import {MathUtils as maath} from "three";
import DeckOfCards from "./DeckOfCards";
import * as THREE from "three";
import * as PlayroomConstants from "./PlayroomConstants";
import {SIZE_FACTOR} from "./PlayroomConstants";


const SetCameraLookAt = () => {
    const { camera } = useThree();
    camera.lookAt(0, 0, 0);
    return null;
};

let startX = 0;
let startPagingPos = 0;

const Playroom = forwardRef((props, ref) => {
    const canvasRef = useRef();
    const deckRef = useRef();

    const [pagingPos, setPagingPos] = useState(0)
    const [isDown, setDown] = useState(false)


    let pointerPos = {x: 0, y: 0};
    const onPointerDown = e => {
        setDown(true)
        startX = e.pageX;
        startPagingPos = pagingPos;
        console.log(e)
    }
    const onPointerMove = e => {
        if(!isDown){
            return;
        }
        let deltaX = e.pageX - startX;
        setPagingPos(maath.clamp(startPagingPos + deltaX * 0.002, 0, 1));    // TODO multiply delta with factor of deck size
    }

    const onPointerUp = e => {
        setDown(false)
    }

    const addCard = (url) => {
        if(deckRef.current){
            deckRef.current.addCard(url);
        }
    }
    useImperativeHandle(ref, () => ({
        addCard,
    }));
    return (
        <Canvas ref={canvasRef} id="playroom"
                style={{touchAction: "none"}}
                onPointerMove={onPointerMove}
                onPointerUp={onPointerUp}
                gl={{ antialias: true, pixelRatio: window.devicePixelRatio }}
                onCreated={({ gl }) => {
                    gl.setClearColor('#000000');
                    gl.toneMapping = THREE.ACESFilmicToneMapping;
                    gl.outputEncoding = THREE.SRGBColorSpace

                    /*
                    gl.enable(gl.DEPTH_TEST);
                    gl.depthFunc(gl.LESS);*/
                }}>
            <SetCameraLookAt />

            <DeckOfCards ref={deckRef} pagingPosition={pagingPos} positionX={-20 * SIZE_FACTOR} onPointerDown={onPointerDown}/>


            <PerspectiveCamera makeDefault position={[0, -70*SIZE_FACTOR, 300*SIZE_FACTOR]} near={120 * SIZE_FACTOR} far={500 * SIZE_FACTOR} attach={"camera"}/>
            <ambientLight intensity={0.4} color={"#ffeeb1"}/>

            <pointLight color="white" position={[-PlayroomConstants.Card.width, 0, 350*SIZE_FACTOR]} intensity={1} decay={0} />
            <pointLight color="white" position={[PlayroomConstants.Card.width, 0, 350*SIZE_FACTOR]} intensity={1} decay={0} />

            <Preload all />

        </Canvas>

    )
})

export default React.memo(Playroom);