import * as THREE from "three";

const get3DPositionFrom2D = (screenPosition, zValue, camera) => {
    // נרמול מיקום ה-2D ל-Clip Space (מערכת הצירים של המצלמה)
    const x = (screenPosition.x / window.innerWidth) * 2 - 1;
    const y = -(screenPosition.y / window.innerHeight) * 2 + 1;

    // יצירת וקטור 3D במערכת הצירים של ה-Clip Space
    const vector = new THREE.Vector3(x, y, 0.5);

    // המרת הוקטור ל-World Space
    vector.unproject(camera);

    // יצירת קרן (ray) מהמצלמה דרך הנקודה המחושבת
    const dir = vector.sub(camera.position).normalize();
    const distance = (zValue - camera.position.z) / dir.z;

    // חישוב נקודת החיתוך במישור ה-Z הרצוי
    const position3D = camera.position.clone().add(dir.multiplyScalar(distance));

    return position3D;
}
const get2DPositionFrom3D = (position3D, camera) => {
    // שכפול הוקטור כדי לא לשנות את הערך המקורי
    const vector = position3D.clone();

    // המרת הקואורדינטות ממערכת הצירים של העולם למערכת הצירים של המסך
    vector.project(camera);

    // נרמול הקואורדינטות ל-Viewport (מ-0 עד רוחב המסך, ומ-0 עד גובה המסך)
    const x = (vector.x * 0.5 + 0.5) * window.innerWidth;
    const y = -(vector.y * 0.5 - 0.5) * window.innerHeight;

    return { x, y };
}

export {get3DPositionFrom2D, get2DPositionFrom3D}