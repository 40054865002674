import React from "react";

import { GoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import {useTranslation} from "react-i18next";
import {useCookies} from "react-cookie";


import "../../scss/Login.scss"
import {GOOGLE_CLIENT_ID, USER_COOKIE_EXPIRATION, COOKIE_DOMAIN} from "../constants";
import userService from "../services/UserService";
import {useLoader} from "./GlobalLoader";

const Login = ({prompt, ...props}) => {
    const { t, i18n } = useTranslation();
    const [cookies, setCookie, removeCookie] = useCookies(["token", "userData"]);

    const { showLoader, hideLoader } = useLoader();

    const onGoogleLogin = async credentialResponse => {
        showLoader(t("login_loader"));
        try {
            let user = await userService.googleLogin(credentialResponse);
            setCookie("userData", user, {path: "/", domain: COOKIE_DOMAIN, maxAge: USER_COOKIE_EXPIRATION})
            setCookie("token", user.token, {path: "/", domain: COOKIE_DOMAIN, maxAge: USER_COOKIE_EXPIRATION})
            console.log(user);
        } catch(err){
            alert(err.message);
        }
        hideLoader();


        // TODO call server for login
    }

    return (
        <div id="login">
            <p>{prompt}</p>
            <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                <GoogleLogin
                    shape="pill"
                    locale={i18n.language}
                    onSuccess={onGoogleLogin}
                    onError={() => {
                        console.log('Login Failed');
                    }}
                />
            </GoogleOAuthProvider>
            {/*
                TODO add user / password login
            */}
        </div>
        )

}

export default React.memo(Login);
