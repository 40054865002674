import React, {useEffect, useRef, useState} from 'react';
import Draggable from 'react-draggable';

import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import "../../css/DraggableTextInput.css";

const DraggableTextInput = (props) => {
    const [className, setClass] = useState("");
    const input = useRef(null);

    useEffect(() => {
        onInput();
        if(props.created){
            input.current.focus();
            input.current.select();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.text, props.fontSize])

    useEffect(() => {
        console.log("useEffect");
        if(!props.created){
            window.getSelection().removeAllRanges();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.color])

    useEffect(() => {
        console.log("useEffect");
        console.log(props.forceFocus);
    }, [props.forceFocus])

    useEffect(() => {
        console.log(props.fontFamily)
        if(!props.created) {
            window.getSelection().removeAllRanges();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.fontFamily])

    let onFocus = e => {
        setClass("focus");
        input.current.select();
        props.onFocus(props.id);
    }
    let onBlur = e => {
        console.log("onBlur timeout");
        console.log(props.forceFocus);
        if(props.forceFocus){
            return;
        }
        setClass("");
        props.onChange(props.id, input.current.value);
        props.onBlur();
    }
    let onKeyDown = e => {
        if(e.keyCode === 13){
            input.current.blur();
        }
    }
    let onDelete = e => {
        console.log("ON DELETE")
        if(props.onDelete){
            props.onDelete();
        }
    }
    let onDragStop = (e, dragElement) => {
        if(e.target.getAttribute("act") === "delete"){
            onDelete(e)
        } else {
            props.onDrag(props.id, {x: dragElement.x, y: dragElement.y});
        }
    }
    let onInput = e => {
        let element = input.current;
        let text = element.value.trim();
        if(text.length && text.charCodeAt(0) >= 0x590 && text.charCodeAt(0) <= 0x5FF){
            input.current.style.direction = "rtl"
        } else {
            input.current.style.direction = "ltr"

        }
        element.style.height = "5px";
        element.style.height = (element.scrollHeight) + "px";
    }

    return <Draggable
        defaultClassName="draggable"
        handle=".handle > div"
        defaultPosition={props.position}
        zIndex={100}
        onStop={onDragStop}>
        <div className={className}>
            <div className="handle" >
                <div className="top align-opp">
                    <IconButton act="delete" style={{margin: "-4px 10px"}}>
                        <DeleteForeverIcon sx={{color: "white", pointerEvents: "none"}}/>
                    </IconButton>
                </div>
                <div className="bottom"></div>
                <div className="left"></div>
                <div className="right"></div>
            </div>
            <textarea ref={input}
                      style={{fontSize: `${props.fontSize}px`, color: props.color, fontFamily: props.fontFamily, direction: props.direction}}
                      placeholder="Text Here"
                      defaultValue={props.text}
                      onContextMenu={e => e.preventDefault()}
                      onFocus={onFocus}
                      onBlur={onBlur}
                      onKeyDown={onKeyDown}
                      onInput={onInput}>
            </textarea>
        </div>
    </Draggable>
}

export default React.memo(DraggableTextInput);