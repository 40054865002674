import React, {useEffect, useRef, useState} from 'react';
import CardMaker from "./js/components/CardMaker";
import {Fonts, OFFLINE_PLAYROOM} from "./js/constants";
import WebFont from 'webfontloader';
import PlayroomNative from "./js/components/playroom/PlayroomNative";
import {BrowserRouter as Router, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import backendService from "./js/BackendService"
import {useCookies} from 'react-cookie';
import './js/i18n';
import "./css/App.css"
import WelcomeScreen from "./js/components/WelcomeScreen";
import {useTranslation} from "react-i18next";
import {generateFirestoreId, getPartyCookieName, withParams} from "./js/GlobalUtils";
import NewPlayroomScreen from "./js/components/NewPlayroomScreen";
import GlobalLoader from "./js/components/GlobalLoader";
import ProtectedRoute from "./js/components/ProtectedRoute";
import MenuBar from "./js/components/MenuBar";
import SessionInfo from "./js/components/SessionInfo";
import {Beforeunload} from 'react-beforeunload';

import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';

import { DialogsProvider } from '@toolpad/core/useDialogs';


function App() {
    WebFont.load({
        google: {
            families: Fonts
        }
    });


    return (
        <Router>
            <AppContent />
        </Router>
    );
}

export const AppContext = React.createContext(null);
let backendServiceListenersAttached = false;

function AppContent() {

    const location = useLocation();
    const navigate = withParams(useNavigate(), location);

    const [uiState, setUIState] = useState("playroom");
    const playroomRef = useRef();
    let playroomId = location.pathname.split("/")[1]
    const [isOwner, setIsOwner] = useState(false);
    const [connected, setConnected] = useState(true);

    const { t, i18n } = useTranslation();

    const [cookies, setCookie] = useCookies(['token']);

    const params = new URLSearchParams(location.search);
    const [lang, setLang] = useState(params.get("lang") || "he");
    const [deckId, setDeckId] = useState(params.get("deckId") || "")
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    let playroomInitialized = false;

    //useBeforeunload(e => e.preventDefault());


    const initPlayroom = () => {
        if(!playroomId) return;

        let partyId = "";
        if(playroomId == OFFLINE_PLAYROOM){
            partyId = "offline";        // TODO TEMP
        } else {
            setConnected(false);
        }

        if(!backendServiceListenersAttached){
            backendService.on("playroom_state", data => {
                setIsOwner(data.isOwner);
                playroomInitialized = true;
            })
            backendService.on("connected", () => {
                setConnected(true);
            })
            backendService.on("disconnected", () => {
                if(playroomInitialized){
                    setConnected(false);
                }

            })
            backendService.on("message", data => {

                switch(data.type){
                    case "error":
                        switch(data.status){
                            case 404:
                                setAlertMessage(data.message)
                                setShowAlert(true);
                                break;
                            case 401:
                                const partyCookie = cookies[getPartyCookieName(playroomId)];
                                if(partyCookie){
                                    partyId = partyCookie.partyId;
                                    console.log(partyId);
                                }
                                if(partyId){
                                    backendService.init(playroomId, partyId, "", partyCookie.fullName, playroomId === OFFLINE_PLAYROOM, lang);
                                } else {
                                    navigate(`/${playroomId}/welcome`);
                                }
                                break;
                        }
                        break;
                }
            })

            backendServiceListenersAttached = true;
        }


        if(!cookies.token){     // TODO ask user how to connect: owner / party
            const partyCookie = cookies[getPartyCookieName(playroomId)];            // prp for Playroom Party ID
            if(partyCookie){
                partyId = partyCookie.partyId;
            }
            if(!partyId){
                navigate(`/${playroomId}/welcome`);
            }
        }
        if(partyId || cookies.token){
            // TODO check for admins user for offline activities!!!
            console.log(lang)
            backendService.init(playroomId, partyId, cookies.token, "", playroomId === OFFLINE_PLAYROOM, lang);
        }

    }

    useEffect(() => {
        i18n.changeLanguage(lang)
    }, [lang])

    useEffect(() => {
        if(playroomId !== "offline") return;
        // TODO getDeck from server and load cards
        if(!deckId) return;
        /*for(let i = 0; i < 60; i++){
            playroomRef.current.addCard(`https://content.vicapro.com/decks/${deckId}/web/${i}.jpg`, generateFirestoreId())
        }*/
    }, [deckId])

    useEffect(() => {

        initPlayroom();
    }, [])

    const onEnterPlayroom = (partyId, fullName) => {
        backendService.init(playroomId, partyId, "", fullName, playroomId === OFFLINE_PLAYROOM, lang);
        navigate(`/${playroomId}`);
    }


    useEffect(() => {
        const currentLanguage = i18n.language;
        document.body.classList.toggle('rtl', currentLanguage === 'he');
        document.body.classList.toggle('ltr', currentLanguage !== 'he');
    }, [i18n.language]);


    useEffect(() => {
        setUIState((location.pathname == "/cardMaker" || location.pathname == "/cardMaker/camera") ? "card_maker" : "playroom")
    }, [location])

    useEffect(() => {
        console.log(uiState);
    }, [uiState])

    const onPopupClose = () => {
        navigate(`/${playroomId}`);  // Navigate back to Playroom
    }

    const showMenu = () => {
        alert(t("menu_TBD"));
    }
    const showManager = () => {
        navigate(`/${playroomId}/manager`);  // Navigate to Manager
    }
    const showCardMaker = () => {
        navigate(`/${playroomId}/cardMaker/camera`);  // Navigate to CardMaker
    }

    const onCardCreated = (url, blob) => {

        navigate(`/${playroomId}`);  // Navigate back to Playroom
        let generatedId = backendService.addCard(blob, {text: "Hi there"})


        if(playroomRef.current){
            playroomRef.current.addCard(url, generatedId);   // Add some appearance effect to the added card
        }
    }

    const onPlayroomCreated = prId => {
        playroomId = prId;
        initPlayroom();
        navigate(`/${playroomId}`, {replace: true})
    }



    const closeAction = (
        <>

            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setShowAlert(false)}
                sx={{flex: "1 0 auto", justifyContent: "end"}}
                style={{flex: "1 0 auto", justifyContent: "end"}}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    );

    return (
        <DialogsProvider>
        <GlobalLoader>
            <Beforeunload onBeforeunload={e => !connected && e.preventDefault()} />
            <AppContext.Provider value={{isOwner: isOwner, playroomId: playroomId}}>
            <div className="App" style={{ overflow: "hidden", height: "100vh", width: "100vw" }}>
                <div style={{display: "flex", flexDirection: "column", position: "absolute", height: "100vh", width: "100vw", overflow: "hidden"}}>
                    {isOwner && <MenuBar playroomId={playroomId} onMenu={showMenu}></MenuBar>}
                    <div style={{flex: "1", flexDirection: "column", position: "relative", overflow: "hidden"}}>
                        <PlayroomNative ref={playroomRef} />
                        <Routes>
                            <Route path="*" element={null} />
                            <Route path="/:playroomId/monitor/*" element={
                                <ProtectedRoute>
                                    <SessionInfo playroomId={playroomId} />
                                </ProtectedRoute>
                            } />
                        </Routes>
                    </div>
                </div>


                <Routes>
                    {['/:playroomId/'].map(path => <Route key={`route_${path}`} path={path} element={
                        <>
                            <div className={"bottom_bar"}>
                                <div className={"add_card_button"} onClick={showCardMaker}></div>
                            </div>

                        </>
                    } />)}

                    <Route path="/:playroomId/welcome" element={
                        <WelcomeScreen playroomId={playroomId} onEnter={onEnterPlayroom}/>
                    } />

                    <Route path="/" element={
                        <NewPlayroomScreen onPlayroomCreated={onPlayroomCreated}/>
                    } />
                    <Route path="/:playroomId/cardMaker/*" element={<CardMaker onClose={onPopupClose} onCardCreated={onCardCreated} />} />
                </Routes>
            </div>
            </AppContext.Provider>
            <Snackbar
                open={showAlert}
                className="dir"
                autoHideDuration={3000}
                TransitionComponent={Slide}
                onClose={e => setShowAlert(false)}
                message={alertMessage}
                action={closeAction}
            />
        </GlobalLoader>
        </DialogsProvider>
    );
}

export default App;
