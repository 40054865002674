import EventEmitter from "events";
import {getApi} from "../GlobalUtils";

class UserService extends EventEmitter {
    constructor() {
        super();
    }

    async googleLogin(googleResponse){

        let res = await getApi("users/login", {provider: "google", googleToken: googleResponse.credential})
        return res.user;
    }
}


const userService = new UserService();
export default userService;