import React from "react";

const TabPanel = ({children, value, index, ...props}) => {

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{flex: "0 1 auto", overflow: "hidden"}}
            {...props}
        >
            {value === index && (
                children
            )}
        </div>
    );
}
export default React.memo(TabPanel);
