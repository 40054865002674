import React, {useContext} from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { AppContext } from '../../App';


const ProtectedRoute = ({children }) => {
    const {playroomId} = useContext(AppContext);
    const [cookies] = useCookies("token");
    const isAuthenticated = !!cookies.token; // בדיקת קיומו של ה-token

    return isAuthenticated ? children : <Navigate to={`/${playroomId}`} />;
};

export default React.memo(ProtectedRoute);