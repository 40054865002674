import {extend, useFrame, useLoader} from "@react-three/fiber";
import {geometry} from 'maath'
import * as THREE from "three";
import {CubeTextureLoader, MathUtils as maath, TextureLoader} from "three";
import React, {useEffect, useRef, useState} from "react";
import * as PlayroomConstants from "./PlayroomConstants"
import {SIZE_FACTOR} from "./PlayroomConstants";

extend({ RoundedPlaneGeometry: geometry.RoundedPlaneGeometry })

const flipGeometryUVs = (geometry) => {
    geometry.attributes.uv.array.forEach((_, index) => {
        if (index % 2 === 0) {
            geometry.attributes.uv.array[index] = 1 - geometry.attributes.uv.array[index];
        }
    });
    geometry.attributes.uv.needsUpdate = true;
};

const textureCube = new THREE.CubeTextureLoader().load(PlayroomConstants.Card.envTextureURLs, function(e){
    try{
        //e.format = THREE["RGBFormat"];
    } catch(err){// TODO look for fallback
    }
    e.mapping = THREE.CubeReflectionMapping;
});

const backTexture = new TextureLoader().load(`${process.env.PUBLIC_URL}/img/cardBack.jpg`);

let cardFrontGeometry = new geometry.RoundedPlaneGeometry(PlayroomConstants.Card.width, PlayroomConstants.Card.height, PlayroomConstants.Card.radius)
cardFrontGeometry.computeVertexNormals()

let cardOutlineGeometry = new geometry.RoundedPlaneGeometry(
    PlayroomConstants.Card.width + PlayroomConstants.Card.outlineWidth,
    PlayroomConstants.Card.height + PlayroomConstants.Card.outlineWidth,
    PlayroomConstants.Card.radius + PlayroomConstants.Card.outlineWidth)
let cardOutlineMaterial = new THREE.MeshBasicMaterial({color: 0x777777, side: THREE.FrontSide})

let cardBackGeometry = new geometry.RoundedPlaneGeometry(PlayroomConstants.Card.width, PlayroomConstants.Card.height, PlayroomConstants.Card.radius)
cardBackGeometry.computeVertexNormals()
flipGeometryUVs(cardBackGeometry);

let cardBackMaterial = new THREE.MeshLambertMaterial({
    color: "white",
    transparent: "false",
    emissive: "#222222",
    side: THREE.BackSide,
    reflectivity: 0.3,
    envMap: textureCube,
    map: backTexture,
    depthTest: true,
    depthWrite: true,
    depthFunc: THREE.LessEqualDepth
})


let pivotOffset = 10 * SIZE_FACTOR;


function Card({ url, position, next, ...props }) {

    const ref = useRef()
    const frontMatRef = useRef();
    const [hovered, hover] = useState(false)
    const [clicked, click] = useState(false)
    const [randomRotZ] = useState(0.2 * (Math.random() - 0.5));
    const [frontTexture, setFrontTexture] = useState(useLoader(TextureLoader, url));
    const [envTexture] = useState(new CubeTextureLoader().load(PlayroomConstants.Card.envTextureURLs))

    const [currentFrontTexture, setCurrentFrontTexture] = useState(null);
    const [deckRotationY, setDeckRotationY] = useState(0);

    useEffect(() => {
        setDeckRotationY(maath.clamp(props.rotationOnDeck, 0, Math.PI));
    }, [props.rotationOnDeck])


    useEffect(() => {
        if(props.useFrontTexture){
            setCurrentFrontTexture(frontTexture)
        } else {
            setCurrentFrontTexture(null)
        }
    }, [props.useFrontTexture])

    useEffect(() => {
        if(frontMatRef.current){
            frontMatRef.current.needsUpdate = true;
        }

        if(currentFrontTexture == null){
            frontTexture.dispose();
        }
    }, [currentFrontTexture])


    const pointerOver = (e) => (e.stopPropagation(), hover(true))
    const pointerOut = () => hover(false)


    useFrame((state, delta) => {
        if(clicked && ref.current.rotation.y < Math.PI){
            ref.current.rotation.y += delta * 8
        }
    })

    //onClick={(event) => {click(!clicked); event.stopPropagation()}}

    return (
        <group rotation-y={deckRotationY} position={[position[0]+pivotOffset, position[1], 0]} ref={ref} >

            {/* FRONT */}
            <mesh rotation-z={randomRotZ} position={[-PlayroomConstants.Card.width / 2 - pivotOffset, 0, position[2]]}
                geometry={cardFrontGeometry}>
                <meshLambertMaterial
                    ref={frontMatRef}
                    color="white"
                    transparent={false}
                    emissive="#111"
                    side={THREE.FrontSide}
                    reflectivity={0.5}
                    envMap={envTexture}
                    map={currentFrontTexture}
                    depthTest={true}
                    depthWrite={true}
                    depthFunc={THREE.LessEqualDepth}
                />
            </mesh>

            {/* OUTLINE */}
            <mesh raycast={() => null} rotation-z={randomRotZ} position={[-PlayroomConstants.Card.width / 2 - pivotOffset, 0, position[2] - PlayroomConstants.Card.outlineWidth / 2]}
                  material={cardOutlineMaterial}
                  geometry={cardOutlineGeometry} />

            {/* BACK */}
            <mesh rotation-z={randomRotZ} position={[-PlayroomConstants.Card.width / 2 - pivotOffset, 0, position[2]]}
                  material={cardBackMaterial}
                  geometry={cardBackGeometry}>


            </mesh>
        </group>
    )
}
export default React.memo(Card);