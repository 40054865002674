export const TextColors = {
    white: "#FFF",
    black: "#000",
    lightblue: "#3b94ef",
    lightgreen: "#74c550",
    yellow: "#fccc5e",
    orange: "#fb8b31",
    red: "#e94057",
    magenta: "#cc006a",
    purple: "#a200ba"
}
export const Fonts = [
    "Rubik", "Times new roman", "Courier", "Comic Neue", "Heebo", "Comfortaa", "Arimo", "Amatic SC"
]
export const CardBorderWidth = 40;
export const OFFLINE_PLAYROOM = "offline";
export const GOOGLE_CLIENT_ID = "181228551059-lfetrjprsho60tehjj65nqkvtp7dledk.apps.googleusercontent.com"
export const USER_COOKIE_EXPIRATION = 14 * 24 * 60 * 60 * 1000;
export const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN || ".vicapro.com";