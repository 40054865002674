import React, {useEffect, useState, useRef} from "react";
import {getFieldComparator, hasNonNullContent, isEmpty, clone} from "../GlobalUtils";
import backendService from "../BackendService";
import {Box, Tab, Tabs} from "@mui/material";
import {useTranslation} from "react-i18next";
import TabPanel from "./TabPanel";
import {Modal, ModalClose, ModalDialog, ModalOverflow} from '@mui/joy';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';



import "../../scss/SessionInfo.scss"
import userService from "../services/UserService";

const byFullName = getFieldComparator("fullName");
const byPopularity = (a, b) => b.selections.length - a.selections.length;
const byCreations = (a, b) => b.creations.length - a.creations.length;

const byFullNameAndActivity = (a, b) => {
    if(!hasNonNullContent(a.selections) && hasNonNullContent(b.selections)){
        return 1;
    }
    if(!hasNonNullContent(b.selections) && hasNonNullContent(a.selections)){
        return -1;
    }
    return byFullName(a, b)
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const SessionInfo = ({playroomId, ...props}) => {
    let [activity, setActivity] = useState({});
    let [cardCreationActivity, setCardCreationActivity] = useState({});
    let [climate, setClimate] = useState({});
    let [detailedCard, setDetailedCard] = useState(null);

    const displayTypeRef = useRef(0);
    const [displayType, setDisplayType] = useState(displayTypeRef.current);
    const { t, i18n } = useTranslation();


    const handlePlayroomState = playroomData => {
        console.log(playroomData.currentSession)
        setActivity(playroomData.currentSession.enrichedActivity);
        setCardCreationActivity(backendService.getCardCreationActivity());
    }
    useEffect(() => {
        console.log(cardCreationActivity)
    }, [cardCreationActivity])

    useEffect(() => {
        let c = {};
        Object.values(activity).map(pa => {
            pa.selections.filter(s => s != null).map(s => {
                s.map(sc => {
                    if (!c[sc.id]) {
                        c[sc.id] = clone(sc);
                        c[sc.id].selections = [];
                        if(sc.type == "user"){
                            c[sc.id].senderFullName = backendService.getParties()[sc.sender].fullName
                        }
                    }
                    c[sc.id].selections.push({id: pa.id, fullName: pa.fullName})
                })
            })
        })

        setClimate(c);
    }, [activity])

    const handleCardsUpdate = addedCard => {
        setCardCreationActivity(backendService.getCardCreationActivity());
    }

    const handleActivityUpdate = activityData => {
        console.log("ACTIVITY UPDATE");
        console.log(activityData);
        console.log(backendService.getCards);
        setActivity(activityData);
    }

    useEffect(() => {
        console.log("SETUP")
        setActivity(backendService.getActivity());
        setCardCreationActivity(backendService.getCardCreationActivity());
        backendService.on("activity", handleActivityUpdate);
        backendService.on("card_added", handleCardsUpdate);
        backendService.on("playroom_state", handlePlayroomState);

        return () => {
            backendService.off("activity", handleActivityUpdate);
            backendService.off("card_added", handleCardsUpdate);
            backendService.off("playroom_state", handlePlayroomState);
        }
    }, [])


    const handleTabChange = (e, newValue) => {
        setDisplayType(newValue);
        displayTypeRef.current = newValue;
    };

    const onCardFullDetails = card => {
        console.log(card);
        setDetailedCard(card)
    }

    const [partyDisplayType, setPartyDisplayType] = useState("selections")
    const partyDisplayTypeChange = e => {
        setPartyDisplayType(e.target.value);
        console.log(e.target.value);
    }

    return (
        <Box id="session_info" sx={{ display: 'flex', flexFlow: "column", height: "100%", backgroundColor: "white", position: "relative"  }}>
            <Tabs className="dir" value={displayType} onChange={handleTabChange} aria-label="basic tabs" selectionFollowsFocus>
                <Tab label={t("tab_parties")} {...a11yProps(0)} />
                <Tab label={t("tab_cards")} {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={displayType} index={0} style={{display: "flex", flexDirection: "column", overflow: "hidden"}}>
                <div className={"scroller"} style={{ flex: 1, overflowY: "auto" }}>
                    <div className="party_activity_title dir align">{t("cards_that...")}
                        <Select variant="standard" color="white" className="small_select" defaultValue="selections" onChange={partyDisplayTypeChange} autoWidth>
                            <MenuItem value="selections">{t("option_selected_by")}</MenuItem>
                            <MenuItem value="creations">{t("option_created_by")}</MenuItem>
                        </Select>
                        {t("...by")}
                    </div>
                    {
                        partyDisplayType == "selections" ?
                        Object.values(activity)
                            .sort(byFullNameAndActivity)
                            .map(a => <PartyActivityPanel key={`${a.id}_panel`} activity={a}/>)
                        : Object.values(cardCreationActivity)
                                .sort(byCreations)
                                .map(a => <PartyCardCreationPanel key={`${a.sender}_creation_panel`} creationActivity={a}/>)
                    }

                </div>
            </TabPanel>
            <TabPanel value={displayType} index={1} style={{display: "flex", flexDirection: "column", overflow: "hidden"}}>
                <div className={"scroller"}>
                    <div>
                        {
                            Object.values(climate)
                                .sort(byPopularity)
                                .map(c => <CardInfoPanel onFullDetails={onCardFullDetails} key={`${c.id}_card_panel`} card={c}/>)
                        }
                    </div>
                </div>
            </TabPanel>
            <TabPanel value={displayType} index={2}>
                TBD
            </TabPanel>

            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={!!detailedCard}
                onClose={() => setDetailedCard(null)}
                className="dir"
            >
                <ModalOverflow>
                    <ModalDialog>
                        <ModalClose />
                        <img style={{marginTop: "20px", boxShadow: "0 2px 6px #0008", borderRadius: "10px"}} src={detailedCard && detailedCard.previewUrl} />

                        {
                            detailedCard && <>
                                <h3 style={{margin: "0"}}>{t("climate_card_selected_by", {count: detailedCard.selections.length, numParties: detailedCard.selections.length})}</h3>
                                {detailedCard.selections.map(s => <div key={`selectorName_${s.fullName}`}>{s.fullName}</div>)}
                            </>
                        }
                    </ModalDialog>
                </ModalOverflow>
            </Modal>
        </Box>
    )
}
const CardInfoPanel = ({card, onFullDetails, ...props}) => {

    const { t, i18n } = useTranslation();

    const showFullDetails = e => {

        if(onFullDetails){
            onFullDetails(card);
        }

    }
    const getSelectorsSummary = selections => {
        let summarySelections = selections.slice(0, 3);
        return <ul>{
            [
                ...summarySelections.map(s => <li key={`${s.id}_li`} >{s.fullName}</li>),
                selections.length > summarySelections.length &&
                <div className="more_selectors">
                    <a onClick={showFullDetails}>ועוד {selections.length - summarySelections.length}</a><br />
                </div>
            ]
        }</ul>;
    }

    useEffect(() => {
        console.log(card.selections.length)
    }, [])

    return <div className="info_panel card_info_panel dir align">
        <img className="card" src={card.previewUrl} onClick={showFullDetails} />
        <div className="card_details">
            <div><b>{t("climate_card_selected_by", {count: card.selections.length, numParties: card.selections.length})}</b>
                {getSelectorsSummary(card.selections)}
            </div>
            {
                card.type === "user" && <p className="card_source">{t("sent_by", {fullName: card.senderFullName})}</p>
            }
        </div>
    </div>
}
const PartyCardCreationPanel = ({creationActivity, ...props}) => {
    return <div className="info_panel party_activity_panel">
        <h2 className="dir align">{creationActivity.senderFullName}</h2>
        <div className={"selections dir"}>
            {Object.values(creationActivity.creations).map((c, i) => <img className="card creation" key={`sel_${creationActivity.sender}_${i}`} src={c.previewUrl} />)}
        </div>
    </div>
}
const PartyActivityPanel = ({activity, ...props}) => {
    return <div className="info_panel party_activity_panel">
        <h2 className="dir align">{activity.fullName}</h2>
        <div className={"selections"}>
            {activity.selections.map((sel, i) => <SelectionDisplay key={`sel_${activity.id}_${i}`} selection={sel} />)}
        </div>
    </div>
}


const SelectionDisplay = ({selection, ...props}) => {

    if(isEmpty(selection)) return <img className="card selection" src="https://app.vicapro.com/img/card_placeholder.jpg"/>;

    if(selection.length > 1){
        return <div className="card selection" count={selection.length}>
            <div></div>
            <div></div>
            <img src={selection.at(-1).previewUrl}/>
        </div>
    }
    return <img className="card selection" src={selection.at(-1).previewUrl} />

}
export default React.memo(SessionInfo);
