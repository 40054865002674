import React, {useState} from "react";

import "../../css/WelcomeScreen.css";
import {useTranslation} from "react-i18next";
import {useCookies} from "react-cookie";
import {generateFirestoreId, getPartyCookieName} from "../GlobalUtils";
import TextField from '@mui/material/TextField';


const COOKIE_EXP = 30 * 60 * 60 * 24 * 1000
const WelcomeScreen = ({playroomId, onEnter, ...props}) => {
    const { t, i18n } = useTranslation();
    const [fullName, setFullName] = useState("");
    const [cookies, setCookie] = useCookies(['token']);

    const handleKeyDown = e => {
        if(e.keyCode == 13 && fullName.trim().length > 1){
            enterPlayroom();
            // put the login here
        }
    }
    const enterPlayroom = e => {
        // TODO validate fullname

        const domainParts = window.location.hostname.split('.');
        const domain = domainParts.length > 1 ? `.${domainParts.slice(-2).join('.')}` : window.location.hostname;

        const partyId = generateFirestoreId();
        setCookie(getPartyCookieName(playroomId), {partyId: partyId, fullName: fullName}, {
            path: '/', // זמין לכל הנתיבים
            expires: new Date(new Date().getTime() + COOKIE_EXP), // תפוגה לאחר חודש
            domain: domain, // קביעת הדומיין
        });

        if(onEnter){
            onEnter(partyId, fullName)
        }
    }
    return (
        <div id="welcome" className="ui_screen">
            <div className="center">
                <h1 className="dir">{t('welcome')}</h1>
                <TextField className="dir" id="standard-basic" label={t("fullName")} variant="standard" defaultValue={fullName} onChange={e => setFullName(e.target.value)} onKeyDown={handleKeyDown}/><br /><br />
                <button disabled={fullName.trim().length < 2} className="text_button default" onClick={enterPlayroom}>{t("enter")}</button>
            </div>

        </div>
    )
}

export default React.memo(WelcomeScreen);
