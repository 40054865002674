import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import Card from "./Card";
import * as PlayroomConstants from "./PlayroomConstants";
import {useScroll} from "@react-three/drei";
import {useFrame} from "@react-three/fiber";
import {MathUtils as maath} from "three";
import {easing} from "maath";
import {SIZE_FACTOR} from "./PlayroomConstants";

const totalCards = 40;

let lastCard = null;

let allCards = [];
for(let i = 0; i < totalCards; i++){
    let card = {
        z: i - totalCards / 2,
        url: `https://content.vicapro.com/decks/chairs/web/${i}.jpg`,
        rotationOnDeck: 0,
        prevCard: lastCard,
        useFrontTexture: false
    }
    if(lastCard){
        lastCard.nextCard = card;
    }
    if(i == totalCards - 1){
        card.useFrontTexture = true;
    }

    allCards.push(card)
    lastCard = card;
}
let pp = {
    pos: 0
}

const DeckOfCards = forwardRef(({pagingPosition, ...props}, ref) => {
    const [cards, setCards] = useState([...allCards]);

    const addCard = (url) => {
        let card = {
            z: allCards.length / 2 - 1,
            url: url,
            rotationOnDeck: 0,
            prevCard: lastCard,
            useFrontTexture: true
        }
        if(lastCard){
            lastCard.nextCard = card;
        }
        allCards.push(card);
        setCards([...allCards])
        lastCard = card;
        updateDeck();
    }
    useImperativeHandle(ref, () => ({
        addCard,
    }));

    const updateDeck = () => {
        setCards([...allCards.map((card, i) => {
            card.z = i - cards.length / 2;
            return card;
        })]);


    }

    useFrame((state, delta) => {
        easing.damp(pp, 'pos', pagingPosition, 0.1, delta)

        let currentCard = lastCard;

        while(currentCard){
            if(currentCard == lastCard){
                currentCard.rotationOnDeck = MaxAngle * pp.pos;
            } else {
                currentCard.rotationOnDeck = currentCard.nextCard.rotationOnDeck - maath.degToRad(PlayroomConstants.Card.maxAngleDeltaDeg)
            }
            if(currentCard.nextCard){
                let nextRealRotation = maath.clamp(currentCard.nextCard.rotationOnDeck, 0, Math.PI);
                if(nextRealRotation > 0.1){
                    currentCard.useFrontTexture = true
                } else {
                    currentCard.useFrontTexture = false
                }
            } else {
                currentCard.useFrontTexture = true;
            }
            let realRotation = maath.clamp(currentCard.rotationOnDeck, 0, Math.PI);
            if(realRotation > Math.PI / 2){
                currentCard.useFrontTexture = true;
            }

            currentCard = currentCard.prevCard || null;
        }
        //setCards(cards => [...cards])
    })

    const onPointerDown = e => {
        if(props.onPointerDown){
            props.onPointerDown(e);
            e.stopPropagation();
        }

    }

    const MaxAngle = maath.degToRad(PlayroomConstants.Card.maxAngleDeltaDeg * (cards.length + 180 / PlayroomConstants.Card.maxAngleDeltaDeg));

    return <group position={[props.positionX, 0, cards.length / 2]} onPointerDown={onPointerDown}>
        {
            cards.map((card, i) => <Card useFrontTexture={card.useFrontTexture} key={i} position={[PlayroomConstants.Card.width / 2, 0, card.z * SIZE_FACTOR]} url={card.url} rotationOnDeck={card.rotationOnDeck}/>)
        }
    </group>
})
export default React.memo(DeckOfCards);
