import React, {ReactElement, useEffect, useRef, useState} from "react";
import {TextColors, Fonts} from "../constants";
import "../../css/TextToolbar.css";

import Slider from '@mui/material/Slider';


const TextToolbar = (props) => {
    const [state, setState] = useState("color");
    const [fontSize, setFontSize] = useState(20);

    let onFontSizeChange = e => {
        props.onFontSizeChange(e.target.value);
    }
    const switchState = e => {
        if(state === "color"){
            setState("font");
        } else {
            setState("color");
        }
    }

    const getPicker = () => {
        switch(state){
            case "color":
                return <div className={"sidebar colorbar"}>
                    <div className={"editor_button switcher"} onClick={switchState} onMouseDown={e => e.preventDefault()}></div>
                    {Object.values(TextColors).map(color =>
                        <div key={color} className={`editor_button picker ${props.color == color ? "selected" : ""}`} style={{backgroundColor: color}} onClick={e => props.onColorChange(color)} onMouseDown={e => e.preventDefault()} ></div>
                    )}
                </div>
            case "font":
                return <div className={"sidebar fontbar"}>
                    <div className={"editor_button switcher"} onClick={switchState} onMouseDown={e => e.preventDefault()}></div>
                    {Fonts.map(font =>
                        <div key={font} className={`editor_button picker ${props.fontFamily === font ? "selected" : ""}`} style={{fontFamily: font}} onClick={e => props.onFontChange(font)} onMouseDown={e => e.preventDefault()} >A</div>
                    )}
                </div>
            default:
                return null;
        }
    }
    return (
        <div className={"toolbar"} style={{backgroundColor: "transparent"}}>
            <input type="range" min={10} max={40} step={0.1} defaultValue={props.fontSize} onChange={onFontSizeChange} onMouseOver={props.onFontSizeChangeStart} onMouseOut={props.onFontSizeChangeEnd}></input>

            {/*<button onClick={props.onTextDelete} onMouseDown={e => e.preventDefault()}>Delete</button>*/}
            {getPicker()}
        </div>
    )
}

export default React.memo(TextToolbar);