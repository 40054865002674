import * as THREE from "three";
import {MathUtils as maath, TextureLoader} from "three";
import * as PlayroomConstants from "./PlayroomConstants";
import {
    CardBackGeometry,
    CardFrontGeometry,
    CardOutlineGeometry,
    CardOutlineMaterial,
    SIZE_FACTOR
} from "./PlayroomConstants";
import "./Interaction3DUtils"

let backTexture;
const USE_LAMBERT_MATERIAL = false;

let cardBackMaterial = new THREE.MeshLambertMaterial({
    color: "white",
    transparent: false,
    emissive: "#222222",
    side: THREE.BackSide,
    shadowSide: THREE.FrontSide,
    reflectivity: 0.3,
    depthTest: true,
    depthWrite: true,
    depthFunc: THREE.LessEqualDepth
})



export class CardNative extends THREE.Group{
    constructor(url, cardId, z, useTexture, prevCard, index){
        super();

        this.url = url;
        this.cardId = cardId;
        this.position.z = z;
        this.prevCard = prevCard;
        this.index = index;
        this.cardGroup = new THREE.Group();

        this.frontTexture = new TextureLoader().load(url);
        let matPropeties = {
            color: "white",
            transparent: false,
            emissive: "#222222",
            side: THREE.FrontSide,
            roughness: 0.1,
            metalness: 0.7,
            reflectivity: 0.5,
            map: useTexture && this.frontTexture,
            depthTest: true,
            depthWrite: true,
            depthFunc: THREE.LessEqualDepth
        };
        let mat = USE_LAMBERT_MATERIAL ? new THREE.MeshLambertMaterial(matPropeties) : new THREE.MeshStandardMaterial(matPropeties)
        mat.needsUpdate = true;
        if(!USE_LAMBERT_MATERIAL){
            // disable direct specular from standard material
            mat.onBeforeCompile = shader => {
                shader.fragmentShader =
                    shader.fragmentShader.replace(
                        'vec3 totalSpecular = reflectedLight.directSpecular + reflectedLight.indirectSpecular;',
                        'vec3 totalSpecular = reflectedLight.indirectSpecular;'
                    )
            };
        }


        this.pivot = new THREE.Object3D();
        this.pivot.position.set(PlayroomConstants.Card.width / 2 + PlayroomConstants.Card.pivotOffsetX, 0, 0)
        this.add(this.pivot);

        this.cardGroup.position.set(-PlayroomConstants.Card.width / 2 - PlayroomConstants.Card.pivotOffsetX, 0, 0)
        this.pivot.add(this.cardGroup);

        let frontMesh = new THREE.Mesh(CardFrontGeometry, mat);
        frontMesh.meshType = "cardFrontMesh";
        this.cardGroup.add(frontMesh);


        frontMesh.onPointerDown = (object, pointer) => {
            if(object === frontMesh){
                // console.log("DOWN: " + url);
                if(this.onPointerDown){
                    this.onPointerDown(this, pointer)
                }
            }
        }
        this.frontMesh = frontMesh;

        frontMesh.onClick = (object, pointer, delta) => {
            if(object === frontMesh && delta < 500){
                // console.log("CLICK: " + url);
                if(this.onClick){
                    this.onClick(this, pointer)
                }
            }
        }

        if(!backTexture){
            backTexture = new TextureLoader().load(`${process.env.PUBLIC_URL}/img/cardBack.jpg`);
            cardBackMaterial.map = backTexture;
            cardBackMaterial.needsUpdate = true;
        }

        let backMesh = new THREE.Mesh(CardBackGeometry, cardBackMaterial);
        backMesh.meshType = "cardBackMesh";
        backMesh.position.z = -3;
        this.backMesh = backMesh;

        this.cardGroup.add(backMesh);

        backMesh.onPointerDown = (object, pointer) => {
            if(object === backMesh){
                // console.log("DOWN: " + url);
                if(this.onPointerDown){
                    this.onPointerDown(this, pointer)
                }
            }
        }

        backMesh.castShadow = true;
        //frontMesh.receiveShadow = true;
        frontMesh.castShadow = true;

        let outlineMesh = new THREE.Mesh(CardOutlineGeometry, CardOutlineMaterial);
        outlineMesh.meshType = "cardOutlineMesh";
        outlineMesh.position.z = -PlayroomConstants.Card.outlineWidth / 2;
        this.cardGroup.add(outlineMesh);
        this.randomizePlacement()

        if(prevCard){       // prevCard = the card BELOW the current one, nextCard = the card ON TOP of the current one
            prevCard.nextCard = this;
            this.prevCard = prevCard;
        }
        this.mat = mat;
        this.virtualAngleRad= 0;
        this.realAngleRad = 0;
    }

    isTopCard(){
        return this.nextCard == null;
    }
    isBottomCard(){
        return this.prevCard == null;
    }

    setRotationInDeck(anglePercentage, total){
        const MaxAngle = maath.degToRad(PlayroomConstants.Card.maxAngleDeltaDeg * (total + 180 / PlayroomConstants.Card.maxAngleDeltaDeg));
        this.setVirtualAngleRad(anglePercentage * MaxAngle)
    }
    setVirtualAngleRad(virtualAngleRad){
        this.virtualAngleRad = virtualAngleRad;
        this.realAngleRad = maath.clamp(this.virtualAngleRad, 0, Math.PI);
        this.pivot.rotation.y = this.realAngleRad;

        this.updateFrontTexture();

        if(this.prevCard){
            this.prevCard.setVirtualAngleRad(this.virtualAngleRad - maath.degToRad(PlayroomConstants.Card.maxAngleDeltaDeg))
        }
    }
    #showFrontTexture(){
        if(!this.mat.map){
            this.mat.needsUpdate = true;
        }
        this.mat.map = this.frontTexture;
    }
    #hideFrontTexture(){
        if(this.mat.map){
            this.mat.needsUpdate = true;
            this.frontTexture.dispose();
        }
        this.mat.map = null;
    }
    updateFrontTexture(){
        if(this.container === "deck"){
            // ON DECK
            if(this.realAngleRad < Math.PI / 2 && (!this.nextCard || this.nextCard.position.y > 0 || this.nextCard.realAngleRad > 0)){
                this.#showFrontTexture();
            } else {
                this.#hideFrontTexture();
            }
        } else {
            // ON SELECTION MAP
            if(!this.nextCard){
                this.#showFrontTexture();
            } else {
                this.#hideFrontTexture();
            }
        }
    }

    updateDeckPivots(pivotZ){
        this.pivot.position.z = pivotZ;
        this.cardGroup.position.z = -pivotZ;
    }
    updateEnv(env){
        this.mat.envMap = env;
        this.mat.needsUpdate = true;
        cardBackMaterial.envMap = env;
        cardBackMaterial.needsUpdate = true;
    }
    faceUpwards(){
        this.cardGroup.rotation.y = -this.realAngleRad;
    }
    faceNaturally(){
        this.cardGroup.rotation.y = 0;
    }
    randomizePlacement() {
        this.cardGroup.rotation.z = (Math.random() - 0.5) * 0.1;

        this.cardGroup.position.set(-PlayroomConstants.Card.width / 2 - PlayroomConstants.Card.pivotOffsetX, 0, 0)
        this.cardGroup.position.x += (Math.random() - 0.5) * 4 * SIZE_FACTOR;
        this.cardGroup.position.y += (Math.random() - 0.5) * 7 * SIZE_FACTOR;

        this.scale.set(1, 1, 1);
    }
    resetPlacement(){
        this.pivot.rotation.y = 0;
        this.cardGroup.rotation.y = 0;
        this.virtualAngleRad = 0;
        this.realAngleRad = 0;
        this.index = 0;
        //this.cardGroup.rotation.z = 0;
        //this.cardGroup.position.x = -PlayroomConstants.Card.width / 2 - PlayroomConstants.Card.pivotOffsetX;
        //this.cardGroup.position.y = 0;
    }
    removeFromLinkedList(){
        if(this.prevCard){
            this.prevCard.nextCard = this.nextCard || null;
        }
        if(this.nextCard){
            this.nextCard.prevCard = this.prevCard || null;
        }
        this.nextCard = null;
        this.prevCard = null
    }

    getAbsolutePosition(){
        let vec = new THREE.Vector3();
        this.frontMesh.getWorldPosition(vec);
        return vec;
    }

}