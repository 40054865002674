import * as THREE from "three";

const raycaster = new THREE.Raycaster();
const mouseDOM = new THREE.Vector2();
const mouse = new THREE.Vector2();
const startDrag = new THREE.Vector2();

let _scene, _camera;
let _canvas;

let downObject = null;
let downTime = null;

let firstDelta = null;
let delta = null;
let dragDirection = null;

let context = {};

// עדכון מיקום העכבר או האצבע
function updateMouse(event) {
    let posProvider = null;
    if (event.touches) {
        if(event.touches.length){
            posProvider = event.touches[0];
        }
    } else {
        posProvider = event;
    }

    if(posProvider){

        const rect = _canvas.getBoundingClientRect();

        posProvider = {
            clientX: posProvider.clientX - rect.left,
            clientY: posProvider.clientY - rect.top,
        }

        if(!firstDelta){
            firstDelta = new THREE.Vector2(posProvider.clientX - mouseDOM.x, posProvider.clientY - mouseDOM.y);
            dragDirection = (Math.abs(firstDelta.x) > Math.abs(firstDelta.y)) ? "horizontal" : "vertical";
        }
        if(delta){
            delta.x = posProvider.clientX - startDrag.x;
            delta.y = posProvider.clientY - startDrag.y;
        }

        mouseDOM.x = posProvider.clientX;
        mouseDOM.y = posProvider.clientY;

        mouse.x = (posProvider.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(posProvider.clientY / window.innerHeight) * 2 + 1;

        if(context.onPointerMove){
            context.onPointerMove({
                pointer: {x: posProvider.clientX, y: posProvider.clientY},
                firstDelta: firstDelta,
                delta: delta,
                dragDirection: dragDirection,
                dragObject: downObject
            })
        }
        return {x: posProvider.clientX, y: posProvider.clientY};
    }
    return null;
}

function onPointerDown(event, _scene, _camera) {
    let pointer = updateMouse(event);  // עדכון המיקום של העכבר או האצבע
    firstDelta = null;
    startDrag.x = pointer.x;
    startDrag.y = pointer.y;
    delta = new THREE.Vector2();
    downTime = new Date().getTime();
    raycaster.setFromCamera(mouse, _camera);
    const intersects = raycaster.intersectObjects(_scene.children, true);
    let object = null;
    if (intersects.length > 0) {
        object = intersects[0].object;
        downObject = object;

        if (object.onPointerDown) {

            object.onPointerDown(object, pointer);  // הפעלת פונקציה מותאמת אישית
        }
    } else {
        downObject = null;
    }
    if(context.onPointerDown){
        context.onPointerDown({pointer: pointer, object: object});
    }
}
let pointerDownHandler = (event) => {

    event.preventDefault()
    onPointerDown(event, _scene, _camera);

}
let pointerClickHandler = event => {
    let pointer = event.preventDefault();

    updateMouse(event);  // עדכון המיקום של העכבר או האצבע
    raycaster.setFromCamera(mouse, _camera);
    const intersects = raycaster.intersectObjects(_scene.children, true);
    if (intersects.length > 0) {

        const object = intersects[0].object;
        if(object === downObject){
            if (object.onClick) {
                object.onClick(object, pointer, new Date().getTime() - downTime);  // הפעלת פונקציה מותאמת אישית
            }
        }
    }
    if(context.onClick){
        context.onClick();
    }
    if(event.type === "touchend"){
        if(context.onPointerUp){
            context.onPointerUp(pointer);
        }
    }
}
let pointerUpHandler = event => {
    event.preventDefault()
    let pointer = updateMouse(event);  // עדכון המיקום של העכבר או האצבע

    raycaster.setFromCamera(mouse, _camera);
    const intersects = raycaster.intersectObjects(_scene.children, true);

    const object = (intersects.length > 0) ? intersects[0].object : null;
    if (object && object.onPointerUp) {
        object.onPointerUp(object, pointer);
    }

    if(context.onPointerUp){
        context.onPointerUp(pointer);
    }


    setTimeout(() => {
        downObject = null;
    }, 10);


}

// eslint-disable-next-line react-hooks/rules-of-hooks
export const useInteraction3D = (element, scene, camera) => {
    console.log("USE")
    _scene = scene;
    _camera = camera;
    _canvas = element;

    // מאזינים לאירועי עכבר ומגע
    window.addEventListener('mousemove', updateMouse, false);
    element.addEventListener('mousedown', pointerDownHandler, false);

    // מאזינים לאירועי מגע
    window.addEventListener('touchmove', updateMouse, false);
    element.addEventListener('touchstart', pointerDownHandler, false);

    element.addEventListener("click", pointerClickHandler, false);
    element.addEventListener("touchend", pointerClickHandler, false);
    window.addEventListener("mouseup", pointerUpHandler, false);

    return context;

    // TODO HANDLE MOUSE WHEEL AND HORIZONTAL DRAG
}



console.log("INTERACTION INIT")