import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react'
import "../../../css/Playroom.css";
import * as THREE from "three";
import {CubeTextureLoader, MathUtils as maath, TextureLoader} from "three";
import * as PlayroomConstants from "./PlayroomConstants";
import {
    Card,
    CARD_MOVEMENT_DAMP,
    PLACEHOLDER_SCALE,
    SELECTION_PANEL_PLACEMENTS,
    SHADOW_MAP_SIZE,
    SIZE_FACTOR
} from "./PlayroomConstants";
import {damp} from "maath/easing";
import {CardNative} from "./CardNative";
import {useInteraction3D} from "./Interaction3DUtils";
import {OrbitControls} from "three/addons";
import {PLACED_CARD_SCALE, SelectionPanel} from "./SelectionPanel";
import backendService from "../../BackendService";
import {get2DPositionFrom3D, get3DPositionFrom2D} from "./Global3dUtils";
import i18n from '../../i18n';
import {useTranslation} from "react-i18next";
import {createLabelCanvas} from "./PlayroomUtils";
import {createSequentialArray} from "../../GlobalUtils";
import frameRateOptimizer, {PIXEL_RATIO_UPDATE} from "./utils/FrameRateOptimizer";

let mainEnv;

// TODO enable shadows on high end computers, this can be decided by platform type or by frame rate
const ENABLE_SHADOWS = (new URLSearchParams(window.location.search).get("shadows") === "true");


const canvas = document.createElement("canvas");
canvas.style.filter = "saturate(1.6) brightness(1.2) contrast(1.2)";

// IMPORTANT: currently we use Three.js r162 for compatibility, as this is the last version to support WebGL1. Safari on mac doesn't support webGL2, and this is a major problem
let gl = canvas.getContext("webgl2") || canvas.getContext("webgl") || canvas.getContext('experimental-webgl');

if(gl){
    gl.enable(gl.DEPTH_TEST);
    gl.depthFunc(gl.LESS);
    //gl.toneMapping = THREE.ACESFilmicToneMapping;
    gl.toneMapping = THREE.ReinhardToneMapping;
    gl.outputEncoding = THREE.SRGBColorSpace;
    gl.outputColorSpace = THREE.SRGBColorSpace;
    canvas.addEventListener("webglcontextlost", e => {
        e.preventDefault();
    }, false);
} else {
    alert("WebGL not supported");
}

const renderer = new THREE.WebGLRenderer({
    antialias: true,
    canvas: canvas,
    context: gl
});

let effectivePixelRatio = window.devicePixelRatio;
const FPSOptimizer = frameRateOptimizer;
FPSOptimizer.on(PIXEL_RATIO_UPDATE, newPR => {
    effectivePixelRatio = newPR;
    renderer.setPixelRatio(effectivePixelRatio);
})

renderer.setPixelRatio( effectivePixelRatio);
renderer.setSize(window.innerWidth, window.innerHeight);
if(ENABLE_SHADOWS){
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
}

const scene = new THREE.Scene();

const camera = new THREE.PerspectiveCamera(50, window.innerWidth / (window.innerHeight), 120 * PlayroomConstants.SIZE_FACTOR, 500 * PlayroomConstants.SIZE_FACTOR);


camera.position.set(0, -120 * SIZE_FACTOR, 330 * SIZE_FACTOR);

const controls = new OrbitControls( camera, renderer.domElement );
camera.lookAt(new THREE.Vector3(0, 30 * SIZE_FACTOR, 0))

controls.enableRotate = false;
controls.enablePan = false;
controls.maxDistance = 353 * SIZE_FACTOR;
controls.minDistance = 230 * SIZE_FACTOR;
controls.enableDamping = true;
controls.dampingFactor = 2;
controls.target = new THREE.Vector3(0, 30 * SIZE_FACTOR, 0)

function setupShadowForLight(light, alpha){
    light.castShadow = true;
    //light.shadowDarkness = alpha;   // deprecated
    light.shadow.camera.near = 1200;
    light.shadow.camera.far = 5000;
    light.shadow.radius = 30;
    light.shadow.mapSize = new THREE.Vector2(SHADOW_MAP_SIZE, SHADOW_MAP_SIZE);

    let d = 50;

    light.shadow.camera.left = -d;
    light.shadow.camera.right = d;
    light.shadow.camera.top = d;
    light.shadow.camera.bottom = -d;
    light.shadow.camera.radius = 1;
    light.shadow.camera.bias = -0.004;
}

// LIGHTING
let ambientLight = new THREE.AmbientLight("#ffeeb1", 0.4);
scene.add(ambientLight)
let pointLight1 = new THREE.PointLight("white", ENABLE_SHADOWS ? 1 : 1, 0, 0);
pointLight1.position.set(-Card.width, 0, 380 * SIZE_FACTOR);
scene.add(pointLight1);

let pointLight2 = new THREE.PointLight("white", ENABLE_SHADOWS ? 1 : 1, 0, 0);
pointLight2.position.set(Card.width, 0, 380 * SIZE_FACTOR);
scene.add(pointLight2);


if(ENABLE_SHADOWS){
    let pointLight3 = new THREE.PointLight("white", 0.7, 600 * SIZE_FACTOR, 0);
    pointLight3.position.set(Card.width * 0.1, Card.height * 0.7, 150 * SIZE_FACTOR);
    scene.add(pointLight3);
    setupShadowForLight(pointLight1);
    setupShadowForLight(pointLight2);
    //setupShadowForLight(pointLight3);
}


// TABLE
let table = new THREE.Mesh(new THREE.PlaneGeometry(2000 * SIZE_FACTOR, 2000 * SIZE_FACTOR), new THREE.MeshLambertMaterial({
    color: "#BBB",
    map: new TextureLoader().load(`${process.env.PUBLIC_URL}/img/texture/wood_2.jpg`)
}))
table.meshType = "tableMesh";
table.receiveShadow = true;

table.material.map.repeat.set(8, 8);
table.material.map.wrapS = THREE.RepeatWrapping;
table.material.map.wrapT = THREE.RepeatWrapping;
table.position.z = -2 * SIZE_FACTOR;
scene.add(table);

let totalCards = 0;
let topCard = null;
let allCardsInDeck = [];
let cardMap = {};

let pp = {y: 0};
let pagingTarget = 0;

let placementDragTarget = {x: 0, y: 0};

const MONITOR_MEMORY = false;
let lastHeap = 0;
if(MONITOR_MEMORY){
    setInterval(() => {
        let mem = performance.memory.usedJSHeapSize;
        console.log(mem - lastHeap);
        lastHeap = mem;
    }, 2000)
}

const phCardMat = new THREE.MeshLambertMaterial({
    color: "white",
    transparent: true,
    opacity: 0.2,
    map: createLabelCanvas(i18n.t("card_needed"), false)
})
const phCard = new THREE.Mesh(PlayroomConstants.CardFrontGeometry, phCardMat);
phCard.position.z = -SIZE_FACTOR;
phCard.scale.set(PLACEHOLDER_SCALE, PLACEHOLDER_SCALE, PLACEHOLDER_SCALE);
scene.add(phCard);

const fitSize = () => {
    let container = document.getElementById("menu_bar");
    let ch = 0;
    if(container){
        ch = container.clientHeight;
    }
    const width = window.innerWidth;
    const height = window.innerHeight - ch;

    // אם יש שינוי בגודל, עדכון הגודל של ה-renderer
    const needResize = canvas.width !== ~~(width * effectivePixelRatio) || canvas.height !== ~~(height * effectivePixelRatio);
    if (needResize) {
        //console.log("RESIZE: ", canvas.height, height, effectivePixelRatio);
        if(camera){
            camera.aspect = width / height;
            camera.updateProjectionMatrix();
        }
        renderer.setSize(width, height);
    }
}

const render = () => {
    fitSize();

    const delta = clock.getDelta();
    // PAGING
    damp(pp, "y", pagingTarget * 1000, 0.1, delta);

    if(dragObject && dragDelta){            // TODO continue animating also after dragging (keep dragObject in memory)

        // POSITION
        let positionTarget = {
            x: placementDragTarget.x,
            y: placementDragTarget.y
        };
        const absPos = dragObject.getAbsolutePosition();
        let posLimit = get3DPositionFrom2D(
            get2DPositionFrom3D(selectionPanel.position, camera),
            absPos.z,
            camera
        );
        if(isPaging){
            positionTarget.y = Math.min(posLimit.y, positionTarget.y);
        }
        damp(dragObject.position, "x", placementDragTarget.x, CARD_MOVEMENT_DAMP, delta);
        damp(dragObject.position, "y", positionTarget.y, CARD_MOVEMENT_DAMP, delta);

        // SCALE
        let targetScale = PLACED_CARD_SCALE - 0.00015 * absPos.z;
        let distY = Math.max(0, posLimit.y - dragObject.position.y) / posLimit.y;
        let scale = targetScale + distY * (1 - targetScale);

        damp(dragObject.scale, "x", scale, CARD_MOVEMENT_DAMP, delta);
        damp(dragObject.scale, "y", scale, CARD_MOVEMENT_DAMP, delta);

        //if(isVerticallyDragging){
            selectionPanel.highlight(dragObject);
        //}
    }

    if(topCard){
        topCard.setRotationInDeck(pp.y / 1000, allCardsInDeck.length)
        if(dragObject && dragDelta && isVerticallyDragging){
            dragObject.faceUpwards();
        }
    }
    controls.update();

    FPSOptimizer.recordFrame();

    renderer.render(scene, camera)
}
const clock = new THREE.Clock();
renderer.setAnimationLoop(() => render());

let isDragging = false;
let isPaging = false;
let isVerticallyDragging = false;
let dragObject = null;
let startDeckPos = 0;
let startCardPosition = null;
let dragDelta = null;

const calculateDraggedCardZ = card => {
    let aboveDeck = allCardsInDeck.length * SIZE_FACTOR;
    let aboveSelectionPanel = selectionPanel.getNextCardZ();
    return Math.max(card.position.z, aboveDeck, aboveSelectionPanel);
}
const onPointerDown = (object, pointer) => {
    if (object && object instanceof CardNative) {
        if(object.container !== "deck" && !object.isTopCard()){
            console.log("not top");
            dragObject = null;
            return;
        }

        if(object.realAngleRad < Card.maxAngleDeltaRad){
            dragObject = object;
        }
        isDragging = true;

        isPaging = object.container === "deck";
        if(!isPaging){
            selectionPanel.removeCard(dragObject);

            //dragObject.position.z = Math.max(dragObject.position.z, allCardsInDeck.length * SIZE_FACTOR) // if true
        } else {
        }
        startDeckPos = pagingTarget;

        startCardPosition = {x: object.position.x, y: object.position.y, z: object.position.z};


    } else {
        dragObject = null;
    }
}
const handlePaging = e => {
    if(!isDragging || !isPaging) return;


    dragDelta = e.delta;


    if(allCardsInDeck.length && e.delta){
        if(!isVerticallyDragging){
            pagingTarget = maath.clamp(startDeckPos + (e.delta.x * 0.0003 * (80 / allCardsInDeck.length)), 0, 1);
        }
    }
    if(e.dragDirection === "vertical"){     // TODO be less strict about this condition
        if(isVerticallyDragging){
            placementDragTarget.y = Math.max(-e.delta.y * Card.verticalDragVelocity, 0);
            placementDragTarget.x = e.delta.x * Card.verticalDragVelocity
        } else {
            if(dragObject && dragObject.realAngleRad < Card.maxAngleDeltaRad && e.delta.y < -Card.minVerticalDrag){
                placementDragTarget.y = -e.delta.y * 6;
                isVerticallyDragging = true;
                if(allCardsInDeck.length > 1){
                    let extra = (1 - dragObject.index / (allCardsInDeck.length - 1)) * (Math.PI / 2 - Card.maxAngleDeltaRad) / allCardsInDeck.length;
                    pagingTarget = extra + Card.maxAngleDeltaRad * (allCardsInDeck.length - dragObject.index - 1) / (Math.PI + Card.maxAngleDeltaRad * (allCardsInDeck.length))
                } else {
                    pagingTarget = 0;
                }

                dragObject.position.z = calculateDraggedCardZ(dragObject);

            }
        }
    }
}
const handlePlacement = e => {
    if(!isDragging || isPaging) return;

    placementDragTarget.x = e.delta.x * Card.verticalDragVelocity + startCardPosition.x;
    placementDragTarget.y = -e.delta.y * Card.verticalDragVelocity + startCardPosition.y;

}
const onPointerMove = e => {

    if(isDragging){
        dragDelta = e.delta;
        if(isPaging){
            handlePaging(e);            // handling cards in deck
        } else {
            handlePlacement(e);         // handling cards on table
        }
    }
}
const onPointerUp = e => {
    if(dragObject){
        let activityUpdated = false;

        dragObject.faceNaturally();
        let isInDeck = dragObject.container === "deck";

        if(selectionPanel.getPanelForCard(dragObject)){
            // TRY PUTTING CARD IN SELECTION PANEL
            if(isInDeck){
                console.log("removing from deck");
                removeCardFromDeck(dragObject);
            }
            console.log("putting in selection panel");
            activityUpdated = selectionPanel.addCard(dragObject);
            console.log(activityUpdated);
        } else {
            // OTHERWISE - RETURN CARD TO DECK
            if(isInDeck){
                dragObject.position.y = 0;
                dragObject.position.x = 0;
                dragObject.position.z = dragObject.index * SIZE_FACTOR;
                dragObject.scale.set(1, 1, 1);
            } else {
                putCardInDeck(dragObject);
                pagingTarget = 0;
                activityUpdated = true;
            }
        }

        if(activityUpdated){
            backendService.updateActivity({
                selections: Object.assign({}, selectionPanel.getContent()),
            })
        }
    }
    isDragging = false;
    isPaging = false;
    isVerticallyDragging = false;
    dragObject = null;
    dragDelta = null;
    placementDragTarget = {x: 0, y: 0};

    selectionPanel.cancelHighlight();
}

const addCard = (url, id, autoPageToTop = true) => {
    if(cardMap[id]){
        console.log("card already exists");
        return;
    }
    let card = new CardNative(
        url,
        id,
        allCardsInDeck.length * SIZE_FACTOR,
        allCardsInDeck.length === totalCards - 1,       // TODO remove this
        topCard,
        allCardsInDeck.length
    )
    card.container = "deck";

    cardMap[id] = card;
    allCardsInDeck.push(card)

    topCard = card;
    scene.add(card)

    card.onPointerDown = onPointerDown;
    if(mainEnv){
        card.updateEnv(mainEnv);
    }

    allCardsInDeck.map((c, i) => c.updateDeckPivots((allCardsInDeck.length / 2 - i) * SIZE_FACTOR));
    if(autoPageToTop){
        pagingTarget = 0;
    }

    if(phCard.parent === scene){
        scene.remove(phCard);
    }


}
const putCardInDeck = card => {
    card.index = allCardsInDeck.length;
    card.position.set(0, 0, card.index * SIZE_FACTOR)
    card.container = "deck";
    card.randomizePlacement();
    allCardsInDeck.push(card);
    card.prevCard = topCard;
    if(topCard){
        topCard.nextCard = card;
        card.nextCard = null;
    }
    topCard = card;
    allCardsInDeck.map((c, i) => c.updateDeckPivots((allCardsInDeck.length / 2 - i) * SIZE_FACTOR));

}
const removeCardFromDeck = card => {

    card.removeFromLinkedList();

    allCardsInDeck.splice(allCardsInDeck.indexOf(card), 1);

    topCard = allCardsInDeck.length ? allCardsInDeck[allCardsInDeck.length - 1] : null;
    allCardsInDeck.map((c, i) => {
        c.index = i;
        c.position.z = i * SIZE_FACTOR;
        c.updateDeckPivots((allCardsInDeck.length / 2 - i) * SIZE_FACTOR)
    });
}

// TODO REMOVE THIS INITIALIZATION, THIS IS ONLY TEMP
for(let i = 0; i < totalCards; i++){
    addCard(`https://content.vicapro.com/decks/chairs/web/${i}.jpg`, Math.random())
}

const selectionPanel = new SelectionPanel(SELECTION_PANEL_PLACEMENTS, 10, [i18n.t("drag_here")])
selectionPanel.position.y = Card.height * 1.2;
selectionPanel.position.z = -SIZE_FACTOR;
scene.add(selectionPanel);


const updateActivity = ownActivity => {
    console.log("updateActivity");
    console.log(ownActivity);
    if(!ownActivity || !ownActivity.selections){
        console.log("no selections");
        return;
    }
    let currentContent = selectionPanel.getContent();
    const slimActivity = ownActivity.selections.map(s => s == null ? [] : s?.map(card => card.id))
    const changeDetected = JSON.stringify(currentContent) != JSON.stringify(slimActivity)
    if(!changeDetected){
        console.log("no change detected");
        return;
    }

    onPointerUp();

    let removedCards = selectionPanel.removeAllCards();

    removedCards.map(c => putCardInDeck(c));

    ownActivity.selections.map((sel, i) => {
        if(!sel) return;

        sel.map(cardData => {
            let card = cardMap[cardData.id];
            if(!card){
                return;
            }
            removeCardFromDeck(card);
            let placementRes = selectionPanel.addCard(card, i);

            if(!placementRes){
                putCardInDeck(card);
            }
        })
    })
}

const PlayroomNative = forwardRef((props, ref) => {
    console.log("Playroom Init");
    const [textureCube] = useState(new CubeTextureLoader().load(PlayroomConstants.Card.envTextureURLs));
    const {t} = useTranslation();

    useEffect(() => {
        i18n.on('languageChanged', lang => {
            selectionPanel.updateLabels(t("drag_here"))
            phCardMat.map.dispose();
            phCardMat.map = createLabelCanvas(t("card_needed"), false)
            phCardMat.needsUpdate = true;
        });

        backendService.on("card_added", cardData => {
            addCard(cardData.url, cardData.id, cardData.sender === backendService.getPartyId());
        })
        backendService.on("playroom_state", playroomData => {

            if(playroomData.currentSession.cards){
                Object.values(playroomData.currentSession.cards)
                    .sort((a, b) => (a.created || 0) - (b.created || 0))        // sort by 'created' ascending
                    .map(card => {
                        addCard(card.url, card.id)
                     })
            }

            if(playroomData.currentSession.config){
                console.log(backendService.getSessionConfig());
                selectionPanel.updateConfig(backendService.getSessionConfig())
            }

            console.log("SET ACTIVITY:");
            let ownActivity = playroomData.currentSession.enrichedActivity[backendService.getPartyId()];
            updateActivity(ownActivity)

        });

        backendService.on("activity", activityData => {
            if(!activityData) return;
            if(!activityData[backendService.getPartyId()]) return;
            updateActivity(activityData[backendService.getPartyId()])
        });
    }, [])


    useEffect(() => {
        textureCube.mapping = THREE.CubeReflectionMapping;
        allCardsInDeck.map(card => card.updateEnv(textureCube));
        mainEnv = textureCube;
    }, [textureCube])


    useImperativeHandle(ref, () => ({
        addCard
    }));

    let context = useInteraction3D(canvas, scene, camera);
    context.onPointerMove = onPointerMove;
    context.onPointerUp = onPointerUp;

    return <>
        <div id={"playroom"} ref={ref => ref && ref.appendChild(canvas)}>

        </div>
    </>
});

export default React.memo(PlayroomNative);