import {MathUtils as maath} from "three";
import {geometry} from "maath";
import * as THREE from "three";
import i18n from "../../i18n";

const flipGeometryUVs = (geometry) => {
    geometry.attributes.uv.array.forEach((_, index) => {
        if (index % 2 === 0) {
            geometry.attributes.uv.array[index] = 1 - geometry.attributes.uv.array[index];
        }
    });
    geometry.attributes.uv.needsUpdate = true;
};


export const SIZE_FACTOR = 10;
export const Card = {
    width: 108 * SIZE_FACTOR,
    height: 156 * SIZE_FACTOR,
    radius: 5 * SIZE_FACTOR,
    outlineWidth: 0.7 * SIZE_FACTOR,
    envTextureURLs: [
        `${process.env.PUBLIC_URL}/img/texture/px.jpg`,
        `${process.env.PUBLIC_URL}/img/texture/nx.jpg`,
        `${process.env.PUBLIC_URL}/img/texture/py.jpg`,
        `${process.env.PUBLIC_URL}/img/texture/ny.jpg`,
        `${process.env.PUBLIC_URL}/img/texture/pz.jpg`,
        `${process.env.PUBLIC_URL}/img/texture/nz.jpg`
    ],
    pivotOffsetX: 5 * SIZE_FACTOR,
    maxAngleDeltaDeg: 60,
    minVerticalDrag: 4 * SIZE_FACTOR,
    verticalDragVelocity: 5
}
Card.maxAngleDeltaRad = maath.degToRad(Card.maxAngleDeltaDeg);


export const CardFrontGeometry = new geometry.RoundedPlaneGeometry(Card.width, Card.height, Card.radius)
CardFrontGeometry.computeVertexNormals()

export const CardOutlineGeometry = new geometry.RoundedPlaneGeometry(
    Card.width +  Card.outlineWidth,
    Card.height + Card.outlineWidth,
    Card.radius + Card.outlineWidth)
export const CardOutlineMaterial = new THREE.MeshBasicMaterial({color: 0xAAAAAA, side: THREE.FrontSide})

export const CardBackGeometry = new geometry.RoundedPlaneGeometry(Card.width, Card.height, Card.radius)
CardBackGeometry.computeVertexNormals()
flipGeometryUVs(CardBackGeometry);

export const SELECTION_PANEL_PLACEMENTS = 3;
export const DEFAULT_PLACEMENT_CONFIG = {size: 1};
export const CARD_MOVEMENT_DAMP = 0;
export const SHADOW_MAP_SIZE = 2048;
export const PLACEHOLDER_SCALE = 1.05;
