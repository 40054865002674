import React, {ReactElement, useEffect, useRef, useState} from "react";
import "../../css/CardMakerToolbar.css";

const CardMakerToolbar = (props) => {
    return <div className={"toolbar editor_toolbar"} >
        <div className="editor_button" onClick={props.onCreateText}>T</div>
        {/*<div className="editor_button camera_button" onClick={props.onShowCamera}></div>*/}
    </div>
}

export default React.memo(CardMakerToolbar);