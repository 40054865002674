import i18next from "i18next";

const API_URL = process.env.REACT_APP_API_URL || "https://api2.vicapro.com"

const buildQueryString = params => {
    return Object.keys(params)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
        .join('&');
}


export const snakeToCamel = str =>
    str.toLowerCase().replace(/([-_][a-z])/g, group =>
        group
            .toUpperCase()
            .replace('-', '')
            .replace('_', '')
    );

export const generateFirestoreId = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let autoId = '';
    for (let i = 0; i < 20; i++) {
        autoId += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return autoId;
}

export const getPartyCookieName = playroomId => {
    return `prpid_${playroomId}`
}

export const objectToArray = (obj, length) => {
    return obj ? Array.from({length, ...obj}, x => x || null) : [];
}
export const clone = obj => {
    return JSON.parse(JSON.stringify(obj));
}
export const isEmpty = arr => {
    return arr == null || !arr.length;
}
export const hasNonNullContent = arr => {
    if(isEmpty(arr)) return false;
    return arr.filter(a => a != null).length > 0;
}
export const getFieldComparator = (field, desc = false) => {
    return (a, b) => {
        if(a[field] < b[field]){
            return desc ? 1 : -1;
        }
        if(a[field] > b[field]){
            return desc ? -1 : 1;
        }
        return 0;
    }
}
export function bitStringToBase64(bitString) {
    const binaryString = bitString.match(/.{1,8}/g).map(byte => String.fromCharCode(parseInt(byte, 2))).join("");
    return btoa(binaryString);
}
export function base64ToBitString(base64String) {
    const binaryString = atob(base64String).split("").map(char => char.charCodeAt(0).toString(2).padStart(8, '0')).join("");
    return binaryString;
}

export const createSequentialArray = size => {
    return Array.from({ length: size }, (v, i) => i);
}

/**
 * returns i element from array, if possible, returns last element otherwise
 * @param array
 * @param i
 */
export const getBestFit = (array, i) => {
    if(!array) return null;
    return array.length > i ? array[i] : array[array.length - 1];
}
export const withParams = (navigate, location) => {
    const persistentParams = ['offline', 'lang'];
    return (url, config = {}) => {
        const queryParams = new URLSearchParams(location.search)
        const filteredParams = new URLSearchParams();
        persistentParams.forEach(param => {
            if (queryParams.has(param)) {
                filteredParams.set(param, queryParams.get(param));
            }
        });
        navigate(`${url}?${filteredParams.toString()}`, config)
    }
}
export const getCookie = name => {
    let value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        value = parts.pop().split(';').shift();
    } else {
        return "";
    }
    if(value[0] == "{"){
        value = JSON.parse(value)
    }
    return value;
}

const parseApiResponse = async res => {
    if(!res){
        // TODO throw generic i18n error
    }
    let json = await res.json();
    if(!json.success){
        throw new Error(json.message)
    }
    return json;
}
export const postApi = async (api, params = null) => {
    let req = {
        method: "POST",
    }
    if(params){
        req.headers = {
            'Content-Type': 'application/json', // הגדרת התוכן כ-JSON
        };
        req.body = JSON.stringify(params);
    }
    let token = getCookie("token");
    let res = await fetch(`${API_URL}/${api}?token=${token}&lng=${i18next.language}`, req)
    return await parseApiResponse(res);
}
export const getApi = async (api, params = {}) => {
    params.token = getCookie("token");
    console.log(getCookie("token"))
    params.lng = i18next.language;
    let res = await fetch(`${API_URL}/${api}?${buildQueryString(params)}`)
    return parseApiResponse(res);
}
export const removeDuplicates = (arrays) => {
    if(!arrays){
        return null
    }
    const seen = new Set();
    return arrays.map(innerArray => {
        if(!innerArray) return null;
        return innerArray.filter(str => {
            if (seen.has(str)) {
                return false; // לא מוסיפים מחרוזות שכבר הופיעו
            } else {
                seen.add(str);
                return true; // מוסיפים מחרוזות חדשות
            }
        });
    });
}