import * as THREE from "three";

import {Card, CardFrontGeometry, SIZE_FACTOR} from "./PlayroomConstants";
import {createLabelCanvas} from "./PlayroomUtils";
import {getBestFit} from "../../GlobalUtils";
import i18n from "../../i18n";

const TOTAL_PANELS = 3;
const PLACEMENT_SCALE = 0.65;
export const PLACED_CARD_SCALE = 0.57;
const IDLE_OPACITY = 0.3;
const HIGHLIGHT_OPACITY = 0.6;
const PLACEMENT_MATERIAL = new THREE.MeshLambertMaterial({
    color: "#FFFFFFFF",
    transparent: true,
    opacity: IDLE_OPACITY
    // emissive: "#FFFFFF"
})

class SelectionPlacement extends THREE.Mesh {
    /**
     *
     * @param maxCards optional argument to enable receiving multiple cards
     * @param label
     */
    constructor(maxCards = 1, label = "") {
        super();
        this.meshType = "selectionPlacementMesh";
        this.geometry = CardFrontGeometry;
        this.label = label;
        this.material = PLACEMENT_MATERIAL.clone();
        if(label){
            this.material.map = createLabelCanvas(label)
        }
        this.cards = [];
        this.maxCards = maxCards;

        this.scale.set(PLACEMENT_SCALE, PLACEMENT_SCALE, PLACEMENT_SCALE);
    }
    updateLabel(label){
        if(label == this.label){
            return;
        }
        this.material.map?.dispose();
        this.label = label;
        this.material.map = createLabelCanvas(label)
        this.material.needsUpdate = true;
    }
    highlight(){
        this.material.opacity = HIGHLIGHT_OPACITY;
    }
    cancelHighlight(){
        this.material.opacity = IDLE_OPACITY;
    }
    isAvailable(){
        return this.cards.length <  this.maxCards;
    }
    addCard(card){
        if(!this.isAvailable()){
            console.log("not available");
            return false
        }

        let pos = this.getWorldPosition(new THREE.Vector3());
        card.position.set(pos.x, pos.y, SIZE_FACTOR * this.cards.length);
        card.resetPlacement();
        card.scale.set(PLACED_CARD_SCALE, PLACED_CARD_SCALE, PLACED_CARD_SCALE);
        card.container = "selection_panel";         // TODO export const!
        card.placement = this;
        card.index = this.cards.length;
        if(this.topCard){
            card.prevCard = this.topCard;
            this.topCard.nextCard = card;
        }

        card.nextCard = null;
        this.topCard = card;
        this.cards.push(card)

        this.cards.map(c => c.updateFrontTexture());

        return true;
    }
    setPositionX(val){
        this.position.x = val;
        let pos = this.getWorldPosition(new THREE.Vector3());
        this.cards.map(card => {
            card.position.x = pos.x;
        })
    }
    getNextCardZ(){
        return SIZE_FACTOR * this.cards.length;
    }
    removeCard(card){
        if(card.placement !== this){
            return false;
        }
        this.cards.splice(card.index, 1);

        card.position.z = SIZE_FACTOR * this.maxCards;
        card.container = "";

        if(this.topCard === card){
            this.topCard = this.cards.length ? this.cards[this.cards.length - 1] : null;
        }

        card.removeFromLinkedList();

        this.cards.map(c => c.updateFrontTexture());
        card.updateFrontTexture();

        return true;
    }

    removeAllCards(){
        let currentCards = [...this.cards];
        while(this.cards.length){
            this.removeCard(this.cards[this.cards.length - 1]);
        }
        return currentCards;
    }

    getCardIds(){
        return this.cards.map(c => c.cardId);
    }

}
export class SelectionPanel extends THREE.Group {
    constructor(placementCount = TOTAL_PANELS, cardsPerPlacement = 1, labels = []) {
        super();
        this.placements = [];

        for (let i = 0; i < placementCount; i++) {

            this.addPlacement(cardsPerPlacement, getBestFit(labels, i));
        }
    }

    addPlacement(size, label){
        let placement = new SelectionPlacement(size, label );
        this.placements.push(placement);
        this.add(placement);

        const placementCount = this.placements.length;
        this.placements.map((p, i) => {
            let x = Card.width * (i - (placementCount - 1) / 2) * PLACEMENT_SCALE * 1.07;
            p.setPositionX(x);
        });
    }
    getPanelForCard(card) {
        let maxReceivingDistance = card.container === "deck" ? 140 * SIZE_FACTOR : 30 * SIZE_FACTOR;
        if (Math.abs(this.position.y - card.position.y) < maxReceivingDistance) {
            let placement = this.placements.filter(p => p.isAvailable()).map(p => {
                return {
                    p: p,
                    d: Math.abs(p.position.x - card.position.x)
                }
            }).reduce((val, agg) => {
                if (val.d < agg.d) {
                    agg.d = val.d;
                    agg.p = val.p;
                }
                return agg;
            }, {d: 10000, p: null}).p;
            return placement;
        }
        return null;
    }

    highlight(card) {
        this.placements.map(p => p.cancelHighlight());
        this.getPanelForCard(card)?.highlight();
    }

    cancelHighlight() {
        this.placements.map(p => p.cancelHighlight());
    }

    addCard(card, placementIndex = -1) {
        this.removeCard(card);
        let p = null;
        if(placementIndex >= 0 && placementIndex < this.placements.length){
            p = this.placements[placementIndex];
        } else {
            p = this.getPanelForCard(card);
        }
        let res = false;
        if (p) {
            res = p.addCard(card);
            if(res){
                card.nextCard = null;
            }
        }
        this.cancelHighlight();
        return p !== null && res;
    }

    removeCard(card) {
        if(!card) return;
        if (card.container === "selection_panel") {
            card.placement.removeCard(card);
        }
    }
    removeAllCards() {
        return this.placements.map(p => p.removeAllCards()).flat();
    }
    getContent(){
        return this.placements.map(p => p.getCardIds());
    }
    getNextCardZ(){
        return this.placements.map(p => p.getNextCardZ()).reduce((val, max) => {
            if(val > max){
                max = val;
            }
            return max;
        }, 0)
    }
    updateConfig({placements}){
        let count = placements.length;
        if(this.placements.length != count){
            if(count > this.placements.length){
                // adding placements
                for(let i = 0; this.placements.length < count; i++){
                    if(i < this.placements.length){
                        this.placements[i].updateLabel(placements[i].label || i18n.t("drag_here"))
                    } else {
                        this.addPlacement(placements[i].size || 1, placements[i].label || i18n.t("drag_here"));
                    }
                }
            } else {
                // TODO remove selected cards and remove placements
            }
        }

        // handle sizes and labels
    }
    updateLabels(labels){
        if(!Array.isArray(labels)){
            labels = [labels];
        }
        this.placements.map((p, i) => {
            p.updateLabel(labels.length > i ? labels[i] : labels[labels.length - 1])
        })
    }

}
