import {Card, SIZE_FACTOR} from "./PlayroomConstants";
import * as THREE from "three";

export const createLabelCanvas = (label, transparentText = true) => {
    const canvas = document.createElement('canvas');
    canvas.width = Card.width;
    canvas.height = Card.height;
    const ctx = canvas.getContext('2d');

    ctx.fillStyle = 'white';
    if(transparentText){
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.globalCompositeOperation = 'xor';
    } else {
        const borderRadius = 20 * SIZE_FACTOR; // רדיוס הפינות, ניתן להתאים לפי הצורך
        const borderWidth = 10 * SIZE_FACTOR; // עובי המסגרת

        ctx.lineWidth = borderWidth;
        ctx.strokeStyle = 'white';

        // ציור המסגרת
        ctx.beginPath();
        ctx.moveTo(borderRadius, 0);
        ctx.lineTo(canvas.width - borderRadius, 0);
        ctx.quadraticCurveTo(canvas.width, 0, canvas.width, borderRadius);
        ctx.lineTo(canvas.width, canvas.height - borderRadius);
        ctx.quadraticCurveTo(canvas.width, canvas.height, canvas.width - borderRadius, canvas.height);
        ctx.lineTo(borderRadius, canvas.height);
        ctx.quadraticCurveTo(0, canvas.height, 0, canvas.height - borderRadius);
        ctx.lineTo(0, borderRadius);
        ctx.quadraticCurveTo(0, 0, borderRadius, 0);
        ctx.closePath();
        ctx.stroke();
    }

    ctx.font = `bold ${30 * SIZE_FACTOR}px Arial`;

    // פיצול הטקסט לשורות לפי \n
    const lines = label.split('\n');

    // חישוב הגובה המדויק של כל שורה
    const lineHeights = lines.map(line => {
        const textDims = ctx.measureText(line);
        return textDims.fontBoundingBoxAscent;
    });

    // חישוב סך הגובה של כל השורות
    const totalTextHeight = lineHeights.reduce((acc, height) => acc + height, 0);

    // קביעת נקודת ההתחלה (Y) כך שהטקסט יהיה ממורכז אנכית
    let startY = (canvas.height - totalTextHeight) / 2;

    lines.forEach((line, index) => {
        const textDims = ctx.measureText(line);
        const x = (canvas.width - textDims.width) / 2; // מיקום ה-x כדי למרכז את השורה

        // מיקום ה-Y של השורה הנוכחית, עם תוספת ל-`ascent`
        startY += lineHeights[index];

        ctx.fillText(line, x, startY);
    });

    return new THREE.CanvasTexture(canvas);
}