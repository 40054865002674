import React, { useState, createContext, useContext } from 'react';
import "../../scss/GlobalLoader.scss"


// יצירת הקשר (Context) לניהול ה-Loader
const LoaderContext = createContext();

export const useLoader = () => {
    return useContext(LoaderContext);
};

const GlobalLoader = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('');

    const showLoader = (text = "") => {
        setLoadingText(text);
        setIsLoading(true);
    };

    const hideLoader = () => {
        setIsLoading(false);
        setLoadingText('');
    };

    return (
        <LoaderContext.Provider value={{ showLoader, hideLoader }}>
            {isLoading && (
                <div className="loader">
                    <div className="loader-text dir">{loadingText}</div>
                </div>
            )}
            {children}
        </LoaderContext.Provider>
    );
};

export default GlobalLoader;
